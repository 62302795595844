import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import withContentfulBlocks from '../components/withContentfulBlocks'

class LegalIndex extends React.Component {
  render () {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <ContentfulPage blocks={this.props.blocks}>
        <Helmet title={`${siteTitle} — Legal and privacy`} />
      </ContentfulPage>
    )
  }
}

export default withContentfulBlocks(LegalIndex)

export const pageQuery = graphql`
  query FSLegalQuery {
    site {
      siteMetadata {
        siteName
      }
    }
    page: contentfulPage(contentful_id: { eq: "whLSrwwHtvmCUgjK6GIAw" }) {
      ...PageBlocksFragment
    }
  }
`
